/* eslint-disable global-require */
import store from '@/store/index';
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import AddSerial from './add-serial/AddSerial.vue';
import {
  getListSerialsApi,
  getDetailSerialApi,
  updateSerialApi,
  updateStatusSerialApi,
  deleteSerialApi,
  getSerialResourcesApi,
  addSerialRoleUserApi,
  addSerialRoleGroupUserApi,
  deleteSerialRoleApi,
} from '@/api/action-defines/serial-management';
import { showToast } from '@/@core/utils/utils';
import { SymbolActionsCode } from '@/@core/data/enum/menuCode';

const UserSiteStatusFilter = ['ACTIVE', 'WAITING'];

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    AddSerial,
    VuePerfectScrollbar,
  },
  data() {
    return {
      search: {
        Form: '',
        Serial: '',
        Status: [],
      },
      listSerials: [],
      usersResource: [],
      groupUsersResource: [],
      listUsers: [],
      listGroupUsers: [],
      serialDetailBody: {},
      addUserSelected: null,
      addGroupUserSelected: null,
      deleteSerialRoleSelected: null,
      usersFields: [
        {
          key: 'UserName',
          class: 'table-cell',
        },
        {
          key: 'actions',
          class: 'table-cell text-right',
        },
      ],
      groupUsersfields: [
        {
          key: 'GroupUserKeyword',
          class: 'table-cell',
        },
        {
          key: 'actions',
          class: 'table-cell text-right',
        },
      ],

      busyProcess: false,
    };
  },
  created() {
    this.getSerialResources();
    this.getListSerials();
  },
  computed: {
    ...mapGetters({
      $lsAllowOperation: 'app/allowOperation',
    }),
    isCanAddSerial() {
      return this.$lsAllowOperation(SymbolActionsCode.ADD);
    },
    isCanEditSerial() {
      return this.$lsAllowOperation(SymbolActionsCode.EDIT);
    },
    isCanDeleteSerial() {
      return this.$lsAllowOperation(SymbolActionsCode.DELETE);
    },
    listStatus() {
      return [
        {
          value: 'ACTIVE',
          text: this.$t('common_acitve'),
        },
        {
          value: 'DEACTIVE',
          text: this.$t('common_deactive'),
        },
      ];
    },
  },
  methods: {
    getSerialResources() {
      getSerialResourcesApi().then((res) => {
        if (res.IsSuccess) {
          this.usersResource = res.Data.Users;
          this.groupUsersResource = res.Data.GroupUsers;
        }
      });
    },

    getListSerials() {
      const BodyData = {
        ...this.search,
        Status: {
          All: this.search.Status.length == this.listStatus.length,
          In: this.search.Status,
        },
      };
      getListSerialsApi({ BodyData }).then((res) => {
        if (res.IsSuccess) {
          this.listSerials = res.Data;
        }
      });
    },

    getSerialDetail(serial) {
      const QueryParameters = {
        serialId: serial.SerialId,
      };
      getDetailSerialApi({ QueryParameters }).then((res) => {
        if (res.IsSuccess) {
          this.serialDetailBody = res.Data;
          this.filterListUser(this.serialDetailBody.Users);
          this.filterListGroupUser(this.serialDetailBody.GroupUsers);
          this.addUserSelected = null;
          this.$refs.addSerialRoleUserForm?.reset();
          this.addGroupUserSelected = null;
          this.$refs.addSerialRoleGroupUserForm?.reset();
        }
      });
    },

    updateSerial(BodyData) {
      this.busyProcess = true;
      updateSerialApi({ BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            this.getListSerials();
            showToast('success', this.$t(res.Messages));
          }
        })
        .finally(() => {
          this.busyProcess = false;
        });
    },

    updateStatusSerial(BodyData) {
      this.busyProcess = true;
      updateStatusSerialApi({ BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            this.serialDetailBody.Status = BodyData.Status;
            this.getListSerials();
            showToast('success', this.$t(res.Messages));
          }
        })
        .finally(() => {
          this.busyProcess = false;
        });
    },

    deleteSerial(BodyData) {
      this.busyProcess = true;
      deleteSerialApi({ BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            showToast('success', this.$t(res.Messages));
            this.getListSerials();
            this.resetForm();
          }
        })
        .finally(() => {
          this.busyProcess = false;
        });
    },

    addSerialRoleUser() {
      this.$refs.addSerialRoleUserForm.validate().then((success) => {
        if (success) {
          const BodyData = {
            SerialId: this.serialDetailBody.SerialId,
            Users: this.addUserSelected.map((user) => ({
              UserId: user.UserId,
            })),
          };
          this.busyProcess = true;
          addSerialRoleUserApi({ BodyData })
            .then((res) => {
              if (res.IsSuccess) {
                this.getSerialDetail(this.serialDetailBody);
                showToast('success', this.$t(res.Messages));
              }
            })
            .finally(() => {
              this.busyProcess = false;
            });
        }
      });
    },

    addSerialRoleGroupUser() {
      this.$refs.addSerialRoleGroupUserForm.validate().then((success) => {
        if (success) {
          const BodyData = {
            SerialId: this.serialDetailBody.SerialId,
            GroupUsers: this.addGroupUserSelected.map((guser) => ({
              GroupUserId: guser.GroupUserId,
            })),
          };
          this.busyProcess = true;
          addSerialRoleGroupUserApi({ BodyData })
            .then((res) => {
              if (res.IsSuccess) {
                this.getSerialDetail(this.serialDetailBody);
                showToast('success', this.$t(res.Messages));
              }
            })
            .finally(() => {
              this.busyProcess = false;
            });
        }
      });
    },

    deleteSerialRole() {
      const BodyData = {
        SerialRoleId: this.deleteSerialRoleSelected.SerialRoleId,
      };
      this.busyProcess = true;
      deleteSerialRoleApi({ BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            this.getSerialDetail(this.serialDetailBody);
            showToast('success', this.$t(res.Messages));
            this.$refs.confirmDeleteUserRole.isOpen = false;
            this.$refs.confirmDeleteGroupUserRole.isOpen = false;
          }
        })
        .finally(() => {
          this.busyProcess = false;
        });
    },

    searchSerial() {
      this.serialDetailBody = {};
      this.getListSerials();
    },

    openAddSerial() {
      this.$refs.addSerial.isOpen = true;
    },

    openActiveConfirm() {
      this.$refs.confirmActive.isOpen = true;
    },

    openDeactiveConfirm() {
      this.$refs.confirmDeactive.isOpen = true;
    },

    openDeleteConfirm() {
      this.$refs.confirmDelete.isOpen = true;
    },

    openDeleteUserRoleConfirm(role) {
      this.deleteSerialRoleSelected = role;
      this.$refs.confirmDeleteUserRole.isOpen = true;
    },

    openDeleteGroupUserRoleConfirm(role) {
      this.deleteSerialRoleSelected = role;
      this.$refs.confirmDeleteGroupUserRole.isOpen = true;
    },

    handleUpdateSerial() {
      this.$refs.serialDetailForm.validate().then(async (success) => {
        if (success) {
          const bodyData = {
            SerialId: this.serialDetailBody.SerialId,
            Serial: this.serialDetailBody.Serial,
            Form: this.serialDetailBody.Form,
          };
          this.updateSerial(bodyData);
        }
      });
    },

    handleDeactiveSerial(confirmInput) {
      const bodyData = {
        SerialId: this.serialDetailBody.SerialId,
        Status: 'DEACTIVE',
        ReasonDeactive: confirmInput,
      };
      this.updateStatusSerial(bodyData);
      this.$refs.confirmDeactive.isOpen = false;
    },

    handleActiveSerial() {
      const bodyData = {
        SerialId: this.serialDetailBody.SerialId,
        Status: 'ACTIVE',
        ReasonDeactive: '',
      };
      this.updateStatusSerial(bodyData);
      this.$refs.confirmActive.isOpen = false;
    },

    handleDeleteSerial(confirmInput) {
      const bodyData = {
        SerialId: this.serialDetailBody.SerialId,
        ReasonDelete: confirmInput,
      };
      this.deleteSerial(bodyData);
      this.$refs.confirmDelete.isOpen = false;
    },

    filterListUser(userRoles) {
      const listUsers = this.usersResource.filter(
        (user) =>
          !userRoles.some((s) => s.UserId === user.UserId) &&
          UserSiteStatusFilter.includes(user.Status) &&
          !user.Expired,
      );
      this.listUsers = [...listUsers];
    },

    filterListGroupUser(gUserRoles) {
      const listGroupUsers = this.groupUsersResource.filter(
        (gUser) => !gUserRoles.some((s) => s.GroupUserId === gUser.GroupUserId),
      );
      this.listGroupUsers = listGroupUsers.map((gUser) => ({
        ...gUser,
        GroupUserKeyword: this.$t(gUser.GroupUserKeyword),
      }));
    },

    resetForm() {
      this.serialDetailBody = {};
      this.$refs.serialDetailForm.reset();
    },
  },
  setup() {
    return {
      UserSiteStatusFilter,
    };
  },
};
