export const GET_LIST_SERIALS = 'se00-serials/se01-get-list';
export const ADD_SERIAL = 'se00-serials/se02-add';
export const GET_DETAIL_SERIAL = 'se00-serials/se03-get-detail';
export const UPDATE_SERIAL = 'se00-serials/se04-update';
export const UPDATE_STATUS_SERIAL = 'se00-serials/se05-update-status';
export const DELETE_SERIAL = 'se00-serials/se06-delete';
export const GET_SERIAL_RESOURCES = 'sr00-serials-roles/sr04-get-resources';
export const ADD_SERIAL_ROLE_USER = 'sr00-serials-roles/sr01-add-user';
export const ADD_SERIAL_ROLE_GROUP_USER = 'sr00-serials-roles/sr02-add-group';
export const DELETE_SERIAL_ROLE = 'sr00-serials-roles/sr03-delete';
