import { $GET, $POST } from '@/api/gateway/gatewayMethods';
import {
  ADD_SERIAL,
  ADD_SERIAL_ROLE_GROUP_USER,
  ADD_SERIAL_ROLE_USER,
  DELETE_SERIAL,
  DELETE_SERIAL_ROLE,
  GET_DETAIL_SERIAL,
  GET_LIST_SERIALS,
  GET_SERIAL_RESOURCES,
  UPDATE_SERIAL,
  UPDATE_STATUS_SERIAL,
} from '@/api/endpoint-defines/serial-management';

export const getListSerialsApi = ({ BodyData }) =>
  $POST({ EndPoint: GET_LIST_SERIALS, BodyData });

export const getDetailSerialApi = ({ QueryParameters }) =>
  $GET({ EndPoint: GET_DETAIL_SERIAL, QueryParameters });

export const addSerialApi = ({ BodyData }) =>
  $POST({ EndPoint: ADD_SERIAL, BodyData });

export const updateSerialApi = ({ BodyData }) =>
  $POST({ EndPoint: UPDATE_SERIAL, BodyData });

export const updateStatusSerialApi = ({ BodyData }) =>
  $POST({ EndPoint: UPDATE_STATUS_SERIAL, BodyData });

export const deleteSerialApi = ({ BodyData }) =>
  $POST({ EndPoint: DELETE_SERIAL, BodyData });

export const getSerialResourcesApi = () =>
  $GET({ EndPoint: GET_SERIAL_RESOURCES, QueryParameters: null });

export const addSerialRoleUserApi = ({ BodyData }) =>
  $POST({ EndPoint: ADD_SERIAL_ROLE_USER, BodyData });

export const addSerialRoleGroupUserApi = ({ BodyData }) =>
  $POST({ EndPoint: ADD_SERIAL_ROLE_GROUP_USER, BodyData });

export const deleteSerialRoleApi = ({ BodyData }) =>
  $POST({ EndPoint: DELETE_SERIAL_ROLE, BodyData });
