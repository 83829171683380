import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { addSerialApi } from '@/api/action-defines/serial-management';
import { showToast } from '@/@core/utils/utils';
import { required } from '@validations';

const serialForm = {
  Serial: '',
  Form: '',
};

export default {
  emits: ['get-list-serials'],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isOpen: false,
      serialBody: { ...serialForm },
      busyProcess: false,
    };
  },
  watch: {
    isOpen(newValue) {
      if (newValue) this.resetForm();
    },
  },
  methods: {
    handleAddSerial() {
      this.$refs.addSerialForm.validate().then(async (success) => {
        if (success) {
          const BodyData = {
            Serial: this.serialBody.Serial,
            Form: this.serialBody.Form,
          };
          this.busyProcess = true;
          await addSerialApi({ BodyData }).then((res) => {
            if (res.IsSuccess) {
              showToast('success', this.$t(res.Messages));
              this.$emit('get-list-serials');
              this.isOpen = false;
              this.resetForm();
            }
          });
          this.busyProcess = false;
        }
      });
    },
    resetForm() {
      this.serialBody = { ...serialForm };
      this.$refs.addSerialForm.reset();
    },
  },
};
